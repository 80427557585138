








































import { Component, Vue, Watch } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import controller from '@/app/ui/controllers/PayrollController'
import { Withdraw } from '@/domain/entities/Payroll'
import { LocalStorage } from '@/app/infrastructures/misc'

interface PeriodRule {
  withdraw: Withdraw
  withdrawId: number
}

@Component({
  components: {
    TextInput,
    Loading,
  },
})
export default class FundsWithdrawal extends Vue {
  controller = controller
  currentPeriod = ''
  withdrawId = ''

  form: Withdraw = {
    minPayoutAmount: 0,
    minSaldo: 0,
    payoutFee: 0,
  }

  created(): void {
    this.currentPeriod = LocalStorage.getLocalStorage('selectedPeriod')
    this.fetchData()
  }

  private fetchData(): void {
    controller.getDetail({
      id: this.$route.params.periodId,
      page: parseInt(<string>this.$route.params.page),
    })
  }

  @Watch('controller.dataPeriodRule')
  setDataPeriodRule(data: PeriodRule): void {
    const withdraw = data.withdraw
    if (data.withdrawId) {
      this.withdrawId = String(data.withdrawId)
    }
    this.form.minPayoutAmount = withdraw?.minPayoutAmount
    this.form.minSaldo = withdraw?.minSaldo
    this.form.payoutFee = withdraw?.payoutFee
  }
}
